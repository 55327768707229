<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <home-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import HomeStatistics from './HomeStatistics.vue'
import i18n from "@/libs/i18n"

export default {
  components: {
    BRow,
    BCol,

    HomeStatistics,
  },
  data() {
    return {
      data: {
        congratulations: {
          name: '',
        },
        statisticsItems: [
          {
            icon: 'ClipboardIcon',
            color: 'light-primary',
            title: '',
            subtitle: i18n.t('Projects.title'),
            customClass: 'mb-2 mb-xl-0',
            to: { name: 'projects-list' },
          },
          {
            icon: 'UsersIcon',
            color: 'light-info',
            title: '',
            subtitle: i18n.t('Clients.title'),
            customClass: 'mb-2 mb-xl-0',
            to: { name: 'clients-list' },
          },
          {
            icon: 'CheckSquareIcon',
            color: 'light-danger',
            title: '',
            subtitle: i18n.t('Tasks.title'),
            customClass: 'mb-2 mb-sm-0',
            to: { name: 'tasks-list' },
          },
          {
            icon: 'ShieldIcon',
            color: 'light-success',
            title: '',
            subtitle: i18n.t('maintenances.title'),
            customClass: '',
            to: { name: 'maintenances-list' },
          },
        ],
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
